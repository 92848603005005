@import "/src/_styles/mixin";

.curriculum_result_header {
    background-color: #EDFFF6;
    margin: 0 auto;
    padding: 0;
    width: 78vw;
    min-height: 30vh;
    overflow: hidden;

    .curriculum_result_heading {
        padding-top: 4.31rem;

        .curriculum_result_heading_font {
            color: #FFF;
            text-align: center;
            font-family: "MyriadPro-Bold";
            font-size: 1.875rem;
        }
    }

    .line_result {
        padding-top: 0.84rem;

        .line-white {
            margin: auto;
            width: 93%;
        }
    }

    .curriculum_result_content {
        padding-top: 0.81rem;

        .curriculum_result_score {
            padding-right: 3.655rem;

            .curriculum_result_score_font {
                text-align: center;
                font-family: "MyriadPro-Bold";
                font-size: 6.625rem;
                // -webkit-text-stroke: 4px #FFF;
                -webkit-text-fill-color: #F29500;
                position: relative;
                z-index: 1;
                letter-spacing:10px;

                &::after {
                    content: attr(title);
                    position: absolute;
                    -webkit-text-stroke: 10px white;
                    left: 0;
                    z-index: -2;
                }

                &::before {
                    content: attr(title);
                    position: absolute;
                    -webkit-text-stroke: 10px white;
                    /* Chris Coyier's favorite color! */
                    left: 0;
                    z-index: -1;
                }
            }
        }

        .curriculum_result_score_detail {
            padding-left: 3.655rem;

            .curriculum_result_score_detail_time {

                .time_result_icon {
                    padding: 0rem 0.65rem 0.48rem 0;
                }

                .time_result_font {
                    color: #FFF;
                    font-family: "MyriadPro-Bold";
                    font-size: 1.5rem;
                }
            }

            .curriculum_result_score_detail_question {
                .question_result_icon {
                    padding: 0.48rem 0.82rem 1rem 0;
                }

                .question_result_font {
                    color: #FFF;
                    font-family: "MyriadPro-Bold";
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.curriculum-container {
    background-color: #EDFFF6;
    margin: 0 auto;
    padding: 0;
    width: 78vw;
    min-height: 70vh;
    overflow: hidden;

    .curriculum-header {
        padding: 1.94rem 0 1.31em 0;

        .curriculum_result_testing_heading {
            color: #4D4D4D;
            text-align: center;
            font-family: "MyriadPro";
            font-size: 1.5rem;
            font-style: normal;
            line-height: normal;
        }

        .curriculum-header-heading {
            color: #4D4D4D;
            text-align: center;
            font-family: "MyriadPro-Bold";
            font-size: 3rem;
            font-style: normal;
            line-height: normal;
        }
    }

    .curriculum-content {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;

        .curriculum-cards {
            width: 100%;

            .active_card_item {
                box-shadow: 0px 0px 12px 0px #00E2A0;
                background-color: red;
            }

            .curriculum-card-item-container {
                width: 100%;

                .curriculum-card-item {
                    padding: 1rem;
                    border-radius: 0.625rem;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    width: 80%;
                    display: flex;

                    .curriculum-thumb-pointer {
                        width: 49%;

                        .thumb-curriculum {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .info-curriculum {
                        width: 51%;
                        padding-left: 1.15rem;

                        .color_heading_class_1 {
                            color: #F29500;
                        }

                        .color_heading_class_2 {
                            color: #EB5757;
                        }

                        .heading-curriculum-pointer {
                            width: 65%;
                        }

                        .heading-curriculum {
                            font-family: "MyriadPro-Bold";
                            font-size: 1.85831rem;
                            font-style: normal;
                            line-height: normal;
                        }

                        .curriculum-suggest-pointer {
                            width: 7rem;
                        }

                        .curriculum-header-suggest {
                            border-radius: 1.25rem;
                            background: #F29500;
                            padding: 0.32rem 0.90rem;
                            width: 7rem;

                            .curriculum-header-suggest-icon {
                                font-size: 1rem;
                                padding-right: 0.15rem;
                            }

                            .curriculum-header-suggest-content {
                                color: #FFF;
                                text-align: center;
                                font-family: "MyriadPro";
                                font-size: 1rem;
                                display: flex;
                                justify-content: flex-start;
                                padding-top: 0.2rem;

                            }
                        }

                        .class-curriculum {
                            padding: 0.62rem 0;
                            color: #4D4D4D;
                            font-family: "MyriadPro-Bold";
                            font-size: 1.5rem;
                            font-style: normal;
                        }

                        .description-curriculum {
                            text-align: justify;
                            color: #4D4D4D;
                            font-family: "MyriadPro";
                            font-size: 1.125rem;
                            font-style: normal;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    .curriculum-footer {
        margin-top: 2.81rem;

        .btn_custom_left {
            margin-right: 0.94rem;

            .btn_custom {
                border-radius: 1.25rem;
                border: 1px solid #00E2A0 !important;


                .font_btn_custom_left {
                    font-size: 1rem;
                    background: linear-gradient(#00E2A0, #00B9B7);
                    font-family: "MyriadPro-Bold";
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
        }

        .btn_custom_disabled {
            margin-left: 0.94rem;
            border-radius: 1.25rem;
            color: #FFF;
            font-family: "MyriadPro-Bold";
            font-size: 1rem;
        }

        .btn_custom_continue {
            margin-left: 0.94rem;

            .btn_custom {
                background-image: linear-gradient(to right, #00b9b7, #00e1a0);
                border-radius: 1.25rem;
                color: #FFF;
                font-family: "MyriadPro-Bold";
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-height: 800px) {

    /*RESET CSS*/
    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        /* font-size: 16px; */
    }

    .curriculum_result_header {
        min-height: 26vh;
    }

    .curriculum-container {
        min-height: 74vh;
    }

    .curriculum_result_header .curriculum_result_heading {
        padding-top: 1rem;
    }

    .curriculum_result_header .curriculum_result_heading .curriculum_result_heading_font {
        color: #FFF;
        text-align: center;
        font-family: "MyriadPro-Bold";
        font-size: 1.3rem;
    }

    .curriculum_result_header .line_result{
        padding-top: 1rem;
    }

    .curriculum_result_header .curriculum_result_content {
        padding-top: 0.5rem
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score {
        padding-right: 0.655rem;
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score .curriculum_result_score_font {
        font-size: 5.5rem;
    }


    .curriculum_result_header .curriculum_result_content .curriculum_result_score_detail .curriculum_result_score_detail_time .time_result_icon {
        padding: 0rem 0.35rem 0.35rem 0;
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score_detail .curriculum_result_score_detail_question .question_result_icon {
        padding: 0.48rem 0.8rem 1rem 0.1rem;
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score_detail .curriculum_result_score_detail_time .time_result_icon {
        padding: 0rem 0.7rem 0.35rem 0;
    }

    .curriculum-container .curriculum-header {
        padding: 1rem 0 1rem 0;
    }

    .curriculum_result_testing_heading {
        font-size: 1.3rem !important;
    }

    .curriculum-container .curriculum-header .curriculum-header-heading {
        font-size: 2.5rem;
    }

    .curriculum-container .curriculum-footer {
        margin-top: 2rem;
    }
}

@media screen and (max-height: 700px) {

    /*RESET CSS*/
    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        /* font-size: 16px; */
    }

    .curriculum_result_header {
        min-height: 24vh;

        .curriculum_result_heading {
            padding-top: 1rem;

            .curriculum_result_heading_font {
                color: #FFF;
                text-align: center;
                font-family: "MyriadPro-Bold";
                font-size: 1.3rem;
            }
        }

        .line_result{
            padding-top: 0.5rem;
        }

        .curriculum_result_content {
            padding-top: 0.5rem;

            .curriculum_result_score {
                padding-right: 0.655rem;

                .curriculum_result_score_font {
                    letter-spacing: 10px;
                    font-size: 3.5rem;
                }
            }

            .curriculum_result_score_detail {
                .curriculum_result_score_detail_question .question_result_icon {
                    padding: 0.48rem 0.8rem 0.2rem 0.1rem;
                }

                .curriculum_result_score_detail_time .time_result_icon {
                    padding: 0rem 0.7rem 0.2rem 0;
                }
            }
        }
    }

    .curriculum-container {
        .curriculum-header {
            padding: 0.5rem 0 0.5rem 0;

            .curriculum-header-heading {
                font-size: 2rem;
            }
        }

        .curriculum_result_testing_heading {
            font-size: 1.1rem !important;
        }

        .curriculum-footer {
            margin-top: 1.5rem;
        }

        .curriculum-content {
            .curriculum-cards {
                .curriculum-card-item-container {
                    .curriculum-card-item {
                        width: 85%;
                    }
                }
            }
        }
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score_detail .curriculum_result_score_detail_time .time_result_font {
        font-size: 1.2rem;
    }

    .curriculum_result_header .curriculum_result_content .curriculum_result_score_detail .curriculum_result_score_detail_question .question_result_font {
        font-size: 1.2rem;
    }
 
}
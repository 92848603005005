.header-ts {
  display: flex;
  align-items: center;
  padding-top: 16px;

  @media screen and (max-width: 768px) {
    padding-top: 12px;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 32px;

    @media screen and (max-width: 768px) {
      margin-left: 16px;
    }
  }

  img {
    width: 90px;
    height: auto;
    margin-top: 0 !important;

    @media screen and (max-width: 768px) {
      width: 50px;
    }
  }

  span {
    font-size: 20px;
    font-weight: 700;
    margin-top: 4px;
    color: #14375F;
    font-family: 'Barlow-Bold';

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

  }
}
.upgrade_container {
  padding: 0rem 0.5rem 0rem;
  padding-top: 1rem;
  .img_upgrade_account {
    width: 100%;
    max-height: 55%;
    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .form_upgrade {
    padding-top: 2rem;
    width: 100%;
    flex: 1;
    .form_upgrade_wrapper {
      width: 100%;
      flex: 1;
      justify-content: flex-start;
    }

    .title_upgrade {
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .title_upgrade_no_padding {
      font-size: 1.5rem;
      padding-bottom: 0rem;
    }

    .input_text_base_container {
      width: 60%;

      .error_text {
        text-align: center;
      }

      .input_text_base {
        background-color: rgba($color: #fff, $alpha: 0.6);
        border-radius: 2rem;
        height: 3.3rem;

        .input_text_base_content {
          display: flex;
          align-items: center;
          justify-content: center;

          .input_text {
            font-size: 1.4rem;
            text-align: center;
          }
        }
      }
    }

    .btn-active {
      margin: 0rem 0rem !important;
      margin-top: 0.3rem !important;
      button {
        padding: 0 3rem;
      }
    }
  }
}

:root {
  --text-color: #4D4D4D;
}

.linking-container {
  width: 100%;
  max-height: 73vh;
  overflow-y: auto;
}

.linking-box {
  padding-bottom: 20px;
  width: 100%;
}

.linking-box-content {
  width: 56%;
}

.linking-parent-divider {
  height: 1px;
  width: 100%;
  background-color: #C4C4C4;
  margin-top: 20px;
}

.linking-box-title {
  color: var(--text-color);
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

.linking-list-parent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.linking-item-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linking-parent-info {
  display: flex;
  gap: 8px;
}

.linking-parent-image-box {
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
  width: 54px;
  height: 54px;
}

.linking-parent-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.linking-parent-info-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.linking-parent-name {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.linking-parent-email {
  color: var(--text-color);
  font-size: 16px;
  line-height: 19px;
}

.linking-parent-action-container {
  display: flex;
  gap: 8px;
}

.linking-parent-action-btn {
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.linking-parent-action-img {
  height: 20px;
  width: 20px;
}

.linking-parent-empty-text {
  color: #EB5757;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 16px;
}

.add-linking-btn-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 40px;
  display: flex;
  justify-content: center;
}
.root-check-box {
  display: flex;
  align-items: center;
  cursor: pointer;


  label {
    cursor: pointer;
  }

  .check-box-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.6px solid #7e7a7a;
    width: 24px;
    height: 24px;
    margin-left: 8px;

    svg path {
      fill: #404041;
    }
  }

  .check-box-div.checked {
    svg path {
      fill: #00bbb5;
    }
  }
}
.register_step {
    .title_register {
        margin-bottom: 18px;

        @media screen and (max-width: 768px) {
            margin-bottom: 14px;
        }
    }

    .step_register_name {
        font-size: 24px;
        line-height: 28px;

        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    .login_text {

        p,
        a {
            font-size: 18px;
        }
    }
}

.register_create_account {
    .step_register_name {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 36px;
    }
}
.skills_assign {
  .skill_assign_box {
    background-color: var(--white-color);
    border-radius: 0.8rem;
    padding: 0.6rem 0.7rem;
    margin-right: 1rem;
    width: 23%;
    max-width: 23%;
    min-width: 23%;

    .skill_assign_level {
      height: 1.75rem;
      width: 65%;
      border-radius: 1rem;
      padding: 0 3%;

      .btn_level {
        height: 70%;

        img {
          height: 100%;
        }
      }
    }

    .btn_count_exercises {
      border: 1px solid #e5e5e5;
      border-radius: 2rem;
      padding: 0.15rem;

      .btn {
        border-radius: 50%;
        background-color: #00e2a0;
        border: none;
        outline: none;
        width: 1.8rem;
        height: 1.8rem;

        img {
          height: 70%;
        }
      }
    }
  }

  .skill_assign_box:last-child {
    margin-right: 0rem;
  }

  .skill_assign_box_lost_root {
    width: 200px;
    max-width: unset;
    min-width: unset;
  }
}

.skills_assigns_proposal {
  padding-top: 3%;
  padding-bottom: 1.5%;
  // min-height: 15rem;
}
.turn-user-lesson-detail-container * {
  color: #4D4D4D;
}

.turn-user-lesson-detail-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 16px 0;
  max-height: calc(100vh - 230px);

  .turn-user-lesson-detail-content {
    width: 70%;
    display: flex;
    flex-direction: column;

    .turn-user-lesson-detail-item {
      width: 100%;
      display: flex;
      gap: 16px;
      padding: 16px 0;
      border-bottom: 1.5px solid #E5E5E5;

      .turn-user-lesson-detail-avatar {
        width: 78px;
        height: 78px;
        border-radius: 8px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .turn-user-lesson-detail-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .turn-user-lesson-detail-name {
          text-wrap: wrap;
          color: #00A69C;
          font-size: 18px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 24px;
          -webkit-box-orient: vertical;

          span {
            color: #FFFFFF;
            font-size: 10px;
            font-weight: bold;
            padding: 6px 16px;
            border-radius: 20px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }


      }
    }

    .turn-user-lesson-detail-item:last-child {
      border-bottom: none;
    }
  }
}
.login_fb_update_email {
    .step_desc {
        margin-bottom: 44px;
        font-size: 18px;
    }
    .button_submit {
        margin-top: 110px;
        margin-bottom: 24px;
    }
}

:root {
  --padding-horizontal-modal: 20px;
}

.uk-modal-body {
  border-radius: 20px;
  box-shadow: 0 1px 6px 0 rgba(21, 27, 38, 0.15);
}

.uk-modal-dialog {
  width: auto;
  width: 340px;
}

.btn-default {
  padding: 0 35px;
  height: 40px;
  line-height: 37px;
  border-radius: 20px;
  border: 2px solid #00a69c;
  font-size: 16px;
  color: #00a69c;
  /* transition: 0.8s; */
  background: #fff;
  font-family: "Myriadpro-SemiBold";
  box-sizing: border-box;
  width: 130px;
}

.btn-bg-blue {
  background-image: linear-gradient(to right, #00e1a0, #00b9b7);
  color: #fff;
  border: none;
}

.btn-popup-gr {
  margin: 20px 0 0;
}

.popup-title {
  font-size: 18px;
  padding: 0 0 20px;
}

.uk-modal-body p {
  font-size: 16px;
}

.radio-gr input {
  margin: -2px 8px 0 0;
}

.radio-body {
  padding: 0 20px;
  display: flex;
  justify-content: center;
}

.btn-popup-gr button:nth-child(1) {
  margin-right: 10px;
}

.btn-popup-gr button:nth-child(2) {
  margin-left: 10px;
}

.message-body textarea {
  width: 100%;
  outline: none;
  resize: none;
  background: #ececec;
  color: #000;
  border: none;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Myriadpro-SemiBold";
  font-size: 16px;
}

.message-body textarea::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

.message-body textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.message-body textarea::placeholder {
  color: #000;
}

.title-span {
  font-family: "Myriadpro-Bold";
  font-size: 16px;
  display: block;
  padding: 0 0 5px;
}

.time-start-end-gr {
  background: #b2eee7;
  border-radius: 20px;
  border: none;
  margin: 0 0 4px;
}

.time-start-end-gr .start,
.time-start-end-gr .end {
  padding: 15px 0;
  border-radius: 20px;
  border: none;
  box-sizing: border-box;
  width: 150px;
}

.time-start-end-gr .start input,
.time-start-end-gr .end input {
  box-sizing: border-box;
  width: 150px;
}

.time-start-end-gr .active {
  background-image: linear-gradient(to right, #00e2a0, #00b9b7);
}

.time-start-end-gr p {
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  box-sizing: border-box;
}

.time-start-end-gr input {
  background: none;
  border: none;
  box-shadow: none;
  text-align-last: center;
  font-family: "Myriadpro-Bold";
  font-size: 16px;
  outline: none;
}

.showtime-gr {
  margin: 0 0 15px;
}

.showtime-gr p {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
}

.showtime-gr .day {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
  line-height: 24px;
  font-family: "Myriadpro-Bold";
  color: #00a79d;
}

.filter-popup-title {
  margin: 0 0 20px;
}

.filter-popup-title h2 {
  font-size: 24px;
  line-height: 24px;
  font-family: "Myriadpro-Bold";
  color: #00a79d;
  padding-left: 5px;
}

.ico_filter_blue {
  height: 17px;
  margin-top: 3px;
}

.title-sp {
  font-size: 18px;
  line-height: 20px;
  font-family: "Myriadpro-Bold";
  padding: 0 0 0 20px;
  margin: 0 0 10px;
  color: #01283a;
  display: block;
}

.title-sp.pad-00 {
  padding: 0;
}

.popup-filter-option {
  overflow: auto;
}

.popup-filter-option .pad-020 .title-sp {
  padding: 0;
}

.input-search {
  height: 50px;
  width: 100%;
  border-radius: 25px;
  box-sizing: border-box;
  background: #eeeeee;
  color: #000;
  font-family: "Myriadpro-Regular";
  border: none;
  outline: none;
  padding-left: 20px;
  font-size: 16px;
  margin: 0 0 25px;
}

.input-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 0.5;
  /* opacity: 1; Firefox */
  font-family: "Myriadpro-Regular";
}

.input-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
  font-family: "Myriadpro-Regular";
}

.input-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
  font-family: "Myriadpro-Regular";
}

.pad-020 {
  padding: 0 20px;
}

.box-input {
  margin: 0 0 15px;
}

.chk-gr {
  margin: 0 0 5px;
}

.chk-gr input {
  width: 20px;
  height: 20px;
  margin: -2px 5px 0 0;
}

.chk-gr label {
  color: #000;
  font-family: "Myriadpro-Regular";
  font-size: 18px;
}

.ico_close_popup {
  cursor: pointer;
}

.close-popup-ab {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.pad0 {
  padding: 0;
}

.avt-pop {
  width: 117px;
  height: 117px;
  border-radius: 50%;
  background: #ccc;
  position: absolute;
  top: -65px;
  left: calc(50% - 58px);
  overflow: hidden;
  border: 3px solid #e9af38;
}

.avt-pop img {
  width: 100%;
  height: 100%;
}

.title-student-pop {
  padding: 0;
  margin: 15px 0 20px;
  font-size: 24px;
  line-height: 28px;
  max-height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title-student-pop.cutom-mg {
  margin: 25px 0 0;
}

.title24 {
  font-size: 24px;
  margin: 0;
  padding: 0 0 10px;
}

.title18 {
  font-size: 18px;
  margin: 0;
  padding: 0 0 20px;
}

.date-dd .react-datepicker-wrapper input {
  background: rgb(0 203 173 / 30%);
  outline: none;
  border: none;
  border-radius: 28px;
  height: 56px;
  width: 100%;
  color: #231f20;
  font-size: 18px;
  text-align: center;
}

.ico-calender-ab {
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
}

.btn-create {
  margin: 0 auto;
}

.custom-area {
  width: 100%;
  height: 120px;
  padding: 20px;
  border: none;
  border-radius: 20px;
  resize: none;
  box-sizing: border-box;
  outline: none;
  background: #ececec;
  color: #000;
  font-size: 18px;
  font-family: "Myriadpro-Regular";
}

.custom-area::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

.custom-area:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.custom-area::placeholder {
  color: #000;
}

.text-center.form-sunE-button {
  margin: 20px 0 0;
}

.form-gr {
  margin: 0 0 10px;
}

.form-gr span {
  display: block;
  color: #231f20;
  font-size: 18px;
  padding: 0 0 5px 10px;
  font-family: "Myriadpro-Regular";
}

.form-gr input:not([type="file"]) {
  width: 100%;
  line-height: 40px;
  border-radius: 20px;
  border: 1px solid #231f20;
  outline: none;
  box-sizing: border-box;
  padding: 0 20px;
  color: #231f20;
  font-size: 18px;
  font-family: "Myriadpro-Regular";
}

.input_files {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #231f20;
  border: 1px solid #231f20;
  outline: none;
  box-sizing: border-box;
  padding: 0 20px;
  color: #231f20;
  font-size: 18px;
  font-family: "Myriadpro-Regular";
}

.form-gr .input_files input[type="file"] {
  color: transparent;
  font-size: 1.1rem;
  /* flex: 1; */
  min-width: 40%;
  width: 40%;
}
/* .form-gr input[type="file"]::after {
  position: relative;
  content: "Chưa chọn tệp";
  color: #8e8e8e;
  text-align: center;
  left: -2rem;
  font-size: 1rem;
} */

.form-gr input::-webkit-input-placeholder {
  /* Edge */
  font-family: "Myriadpro-Regular";
}

.form-gr input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Myriadpro-Regular";
}

.form-gr input::placeholder {
  font-family: "Myriadpro-Regular";
}

.khoi {
  z-index: 10;
}

.khoi .select-options {
  z-index: 9;
  max-height: 300px;
}

.ky-nang {
  z-index: 8;
}

.ky-nang .select-options {
  z-index: 7;
  max-height: 200px;
}

.uk-modal .uk-modal-body.showImg {
  padding: 0;
  border-radius: 0;
}

.showImg {
  height: calc(100vh - 200px);
}

.zoomImg {
  width: 100%;
  height: 100%;
}

.zoomImg img {
  height: 100%;
}

.btn-dkvl {
  width: 164px;
  padding: 0 20px;
}

.title-sp-gray {
  color: #686868;
  font-size: 16px;
  line-height: 135%;
  font-family: "Myriadpro-Bold";
}

.uk-modal-body p.text-edit {
  color: #00a69c;
  font-size: 24px;
  line-height: 135%;
  font-family: "Myriadpro-Bold";
  margin: 0 0 15px;
}

.input-text-gr input {
  width: 100%;
  height: 44px;
  border-radius: 22px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #7f7f7f;
  padding: 0 15px;
  font-size: 16px;
  line-height: 44px;
  font-family: "Myriadpro-Light";
  margin: 10px 0;
}

.input-text-gr input::-webkit-input-placeholder {
  /* Edge */
  font-family: "Myriadpro-Regular";
}

.input-text-gr input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Myriadpro-Regular";
}

.input-text-gr input::placeholder {
  font-family: "Myriadpro-Regular";
}

.radio-gr-add-content {
  margin: 0 0 10px;
}

.radio-gr-add-content input {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.set-180.class-slect-time .slect-time .react-datepicker__input-container input {
  width: 170px;
}

.break-all {
  word-break: break-all;
}

.t-title-w {
  background: #00a69c;
  padding: 8px 20px;
  border-radius: 17px;
}

.t-title-w p {
  font-family: "Myriadpro-Bold";
  font-size: 16px;
  color: #fff;
}

.r-80 {
  width: 80px;
}

.r-100 {
  width: 100px;
}

.rm-20 {
  width: 20px;
}

.tc-list {
  padding-right: 20px;
}

.tc-content {
  padding: 15px 10px 5px;
  background: #e6e7e8;
  border-radius: 20px;
  margin: 10px 0;
}

.tc-content.tc-sum {
  padding: 10px;
  margin-right: 20px;
}

.tc-content.tc-sum p {
  font-family: "Myriadpro-Bold";
  color: #000;
}

.title-regular-black {
  font-family: "Myriadpro-Regular";
  color: #000;
  font-size: 16px;
  margin: 0 0 10px;
}

.tc-item {
  padding: 5px 0;
  border-bottom: 1px solid #787878;
}

.tc-item:last-child {
  border-bottom: none;
}

.tc-item input {
  color: #787878;
  font-family: "Myriadpro-Regular";
  font-size: 16px;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: #e6e7e8;
}

.tc-item .r-100 input {
  text-align: center;
  padding-right: 20px;
}

.rm-btn {
  position: absolute;
  right: -35px;
  top: 4px;
  cursor: pointer;
}

.btn-add-tc {
  padding: 10px 0;
}

.title-regular-black input[type="radio" i] {
  margin: -2px 5px 0 0;
}

.wd-c {
  width: 130px;
}

.chambai.writing .uk-modal-dialog {
  width: auto;
}

.help-popup h2 {
  font-family: "Myriadpro-Regular";
  font-size: 24px;
  color: #000;
  margin: 0 0 20px;
}

.help-popup p {
  font-family: "Myriadpro-Regular";
  font-size: 16px;
  color: #686868;
  margin: 0 0 30px;
}

@media screen and (max-height: 800px) {
  .uk-modal-body .wh20.chk-custom-gr input:checked + label:after {
    top: -8px;
  }

  .set-180.class-slect-time
    .slect-time
    .react-datepicker__input-container
    input {
    width: 135px;
  }

  .input-text-gr input {
    height: 32px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 32px;
  }

  .title-sp-gray {
    font-size: 16px;
  }
}

@media screen and (max-height: 700px) {
  .btn-default {
    padding: 0 25px;
    height: 32px;
    line-height: 29px;
    border-radius: 16px;
    font-size: 13px;
    width: 80px;
  }

  .wd-c {
    width: 100px;
  }

  .uk-modal-body p {
    font-size: 13px;
  }

  .uk-modal-dialog {
    width: auto;
    width: 300px;
  }

  .title24 {
    font-size: 18px;
  }

  .date-dd .react-datepicker-wrapper input {
    border-radius: 20px;
    height: 40px;
    width: 100%;
    font-size: 14px;
  }

  .ico-calender-ab {
    top: 8px;
    left: 16px;
  }

  .close-popup-ab {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .lichngay-teacher .week-cell,
  .lichngay-teacher .day-cell {
    flex-basis: calc(100% / 7 - 24px);
    margin: 5px 12px;
  }

  .form-gr input {
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    padding-left: 16px;
    font-size: 14px;
  }

  .title-sp {
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 0 16px;
  }

  .chk-gr label {
    font-size: 14px;
  }

  .custom-width {
    width: 380px !important;
  }

  .ico_close_popup {
    width: 20px;
    height: 20px;
  }
}

.__img-detail {
  width: auto;
  height: 200px;
  border-radius: 20px;
}

.__img-detail img {
  width: 100%;
  height: 100%;
}

.__vocabulary span {
  color: #00bbb5;
  font-size: 18px;
}

.__vi_mean {
  font-family: "MyriadPro-SemiBold";
}

.justify-content-between {
  justify-content: space-between;
}

.title-modal {
  font-family: "MyriadPro-SemiBold";
  margin-bottom: 20px;
  justify-content: left;
}

.title-modal .thumb2 {
  left: 10px;
}

.content-modal {
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  min-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

.btn-flash-popup-vocabulary {
  border: none;
  background: none;
  position: absolute;
  left: 45%;
  bottom: -15px;
}

.img-flash-popup-vocabulary {
  width: 35px;
  height: 35px;
  background: #fff;
  padding: 5px;
  border-radius: 35%;
}

/* Zoom in Image */
.icon_image_container {
  top: 0.5rem;
  right: 1rem;
}

.ico_image {
  width: 2.2rem;
  margin: 0.8rem;
  cursor: pointer;
}

.rotated-image {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* Popup Edit Homework */

.img_close_popup {
  top: 10px;
  right: 10px;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}

.info-container {
  margin-top: 16px;
  padding-left: 40px;
}

.info-row-box {
  display: flex;
  width: 100%;
  padding: 0 32px;
}

.label-info-left {
  width: 40%;
}

.give-turn-homework-modal {
  z-index: 99;
}

.give-turn-homework-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-horizontal-modal);
  align-self: center;
}

.give-turn-homework-header span {
  color: #00bbb5;
  font-size: 24px;
  font-weight: 700;
}

.give-turn-homework-header button {
  background-color: unset;
  border-width: 0;
}

.give-turn-homework-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.give-turn-title {
  padding: 0 var(--padding-horizontal-modal);
}

.give-turn-check-all {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
  margin: 0 var(--padding-horizontal-modal);
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #cdcdcd;
}

.give-turn-check-all label {
  font-weight: 700;
}

.give-turn-list {
  /* overflow-y: auto; */
  padding: 0 var(--padding-horizontal-modal);
  flex: 1;
}

.give-turn-item {
  border-bottom: 1px solid #cdcdcd;
}

.give-turn-homework-footer {
  display: flex;
  flex-direction: column;
  padding: 16px var(--padding-horizontal-modal) 0;
}

.give-turn-date-range {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.give-turn-date {
  height: 44px !important;
  margin: 0 !important; 
}

.give-turn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.give-turn-action button {
  width: unset;
}

.edit-student-xls-modal {
  z-index: 99;
}

.edit-student-xls-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-horizontal-modal);
  align-self: center;
}

.edit-student-xls-header span {
  color: #00bbb5;
  font-size: 24px;
  font-weight: 700;
}

.edit-student-xls-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 16px 32px;
  gap: 12px;
}

.edit-student-xls-body .input_text_base_container .input_text_base,
.edit-student-xls-body .input_date_base_container .input_date_base {
  height: 44px;
  margin-bottom: 4px;
}

.edit-student-xls-body .input_text_base_container .input_text_base .input_text_base_content .icon_label, 
.edit-student-xls-body .input_date_base_container .input_date_base .icon_label {
  margin-right: 12px;
}

.edit-student-xls-body .input_text_base_container .error_text {
  margin-bottom: 0;
}

.info-student-avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #ccc;
  align-self: center;
  padding: 2px;
  margin-bottom: 24px;
}

.info-student-avatar img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.edit-student-xls-label {
  padding-left: 8px;
  color: #b8b8b8;
}

.edit-student-xls-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.list-student-added-fail-modal {
  z-index: 9;
}

.list-student-added-fail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-horizontal-modal);
  align-self: center;
}

.list-student-added-fail-header span {
  /* color: #00bbb5; */
  font-size: 24px;
  font-weight: 700;
}

.list-student-added-fail-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 16px 32px;
  gap: 16px;
}

.student-added-fail-item {
  display: flex;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  gap: 12px;
  border-radius: 12px;
}

.student-added-fail-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-added-fail-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.student-added-fail-info {
  display: flex;
  flex-direction: column;
}

.list-student-added-fail-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.filter-lesson-topic-modal {
  z-index: 99;
}

.filter-lesson-topic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-horizontal-modal);
}

.filter-lesson-topic-header span {
  color: #00bbb5;
  font-size: 24px;
  font-weight: 700;
}

.filter-lesson-topic-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  padding: 16px 32px;
  gap: 12px;
}

.filter-lesson-topic-body .input_text_base_container .input_text_base,
.filter-lesson-topic-body .input_date_base_container .input_date_base {
  height: 44px;
  margin-bottom: 4px;
}

.filter-lesson-topic-body .input_text_base_container .input_text_base .input_text_base_content .icon_label, 
.filter-lesson-topic-body .input_date_base_container .input_date_base .icon_label {
  margin-right: 12px;
}

.filter-lesson-topic-body .input_text_base_container .error_text {
  margin-bottom: 0;
}

.filter-lesson-topic-label {
  padding-left: 8px;
  color: #b8b8b8;
  margin-bottom: 4px;
}

.filter-lesson-topic-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.popup-select-curriculum {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9997;
  cursor: default;
}

.popup-select-curriculum .overlay-select-curriculum {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 9998;
}

.popup-select-curriculum .container-select-curriculum {
  position: relative;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 24px;
  width: 56%;
  height: 80vh;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-select-curriculum .content-select-curriculum {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-select-curriculum .mx-container-select-curriculum {
  margin: 0 24px;
}

.container-select-curriculum .px-container-select-curriculum {
  padding: 0 24px;
}

.content-select-curriculum .box-select-grade {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  align-self: flex-end;
  height: 50px;
  width: 40%;
  border-radius: 25px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  transform-style: preserve-3d;
}

.box-select-grade .select-curriculum-grade-list {
  position: absolute;
  max-height: 60vh;
  left: 0;
  top: 50%;
  right: 0;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 32px 0 16px ;
  transform: translateZ(-10px);
}

.select-curriculum-grade-list .select-curriculum-grade-item {
  line-height: 40px;
  padding: 0 24px;
}

.select-curriculum-grade-list .select-curriculum-grade-item:hover {
  background-color: #cdcdcd;
  cursor: pointer;
}

.content-select-curriculum .curriculum-by-grade-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 8px;
}

.content-select-curriculum .curriculum-by-grade-item {
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  border-bottom: 1px solid #999999;
  /* border-radius: 24px; */
  padding: 16px 24px;
  display: flex;
  gap: 16px;
}

.content-select-curriculum .curriculum-by-grade-item:last-child {
  border-bottom: none;
}

.content-select-curriculum .curriculum-by-grade-item:hover {
  /* opacity: 0.6; */
  cursor: pointer;
}

.curriculum-by-grade-item .curriculum-by-grade-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.curriculum-by-grade-item .curriculum-by-grade-img img {
  width: 60%;
  height: 66%;
}

.curriculum-by-grade-item .curriculum-by-grade-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.curriculum-by-grade-item .curriculum-by-grade-info p {
  line-height: 24px;
}
@import "/src/_styles/mixin";

.policyPage {
  position: relative;

  // background-color: var(--white-color);
  .slider_bg {
    width: 100%;
    position: relative;
  }

  .bg_side {
    position: fixed;
    top: 5%;
    right: 0;
    left: 0;
    // height: 10rem;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include screen_mobile {
      top: 10%;
    }
  }

  .content {
    // padding-bottom: 10rem;
    z-index: 10;
    // background-color: var(--white-color);

    @include screen_mobile {
      // padding-bottom: 7rem;
    }

    .content_1 {
      // padding: 0 0 0rem 10%;
      z-index: 10;

      @include screen_mobile {
        // padding-left: 4%;
      }

      .list_about,
      .list_item_dot {

        li,
        .item_dot {
          &::before {
            content: "\2022 ";
            color: red;
            padding-right: 0.5em;
            font-size: 1.7rem;

            @include screen_mobile {
              font-size: 1.4rem;
            }
          }
        }
      }

      .img_about {
        max-height: 80%;
        max-width: 40%;

        @include screen_mobile {
          max-height: 60% !important;
          // height: 10rem;
          max-width: 96% !important;
          float: right;
          position: relative;
        }
      }

      .content_rules {
        // padding-right: 6rem;
        margin-top: 0;

        @include screen_mobile {
          // padding-right: 4%;
        }
      }
    }

    .transparent_side {
      height: 38rem;
      width: 100%;
      position: relative;

      @include screen_mobile {
        height: 33rem;
      }
    }

    .content_2,
    .content_3 {
      // padding: 0 6rem 3rem 7rem;

      @include screen_mobile {
        // padding-left: 4%;
        // padding-right: 4%;
      }
    }
  }
}
.otp-input-container {
  display: flex;
  gap: 16px;

  .otp-input {
    width: 36px;
    height: 50px;
    border-radius: 8px;
    outline: 1px solid #999;
    border: 1px solid #999;
    font-size: 30px;
    text-align: center;
  }

  .otp-input:focus-visible {
    outline: 1px solid #00b9b7;
    border: 1px solid #00b9b7;
  }

  .otp-input::-webkit-outer-spin-button,
  .otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide spin buttons in Firefox */
  .otp-input[type="number"] {
    -moz-appearance: textfield;
  }
}
:root {
  --height-list: calc(100vh - 250px);
}

.detail-assigned-home-work-page {

  .w-per-33 {
    width: 33.333%;
  }

  .detail-assigned-home-work-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .detail-assigned-tab-view-container {
      display: flex;
      justify-content: center;
      width: 100%;

      .detail-assigned-tab-view-content {
        width: 54%;
        display: flex;
        border-bottom: 1px solid #C4C4C4;

        .detail-assigned-tab-view-item {
          width: 50%;
          padding: 0 16px 6px;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          span {
            font-size: 20px;
            color: #C4C4C4;
          }
        }

        .detail-assigned-tab-view-item:hover span {
          color: #00BEB4;
          font-weight: bold;
        }

        .detail-assigned-tab-view-item.active {
          border-bottom: 3px solid #F8AF36;

          span {
            font-weight: bold;
            color: #00BEB4;
          }
        }
      }
    }

    .detail-assigned-list-student-container {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow-y: auto;
      padding: 16px 0;
      max-height: var(--height-list);

      .detail-assigned-list-student-content {
        width: 60%;
        gap: 16px;
        display: flex;
        flex-direction: column;

        .detail-assigned-item-student:hover {
          opacity: 0.8;
        }

        .detail-assigned-item-student {
          width: 100%;
          background-color: #FFFFFF;
          border-radius: 8px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          padding: 6px 12px;
          display: flex;
          gap: 10px;
          cursor: pointer;

          .detail-assigned-avatar-student {
            padding: 2px;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .detail-assigned-info-student {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            span {
              font-size: 16px;
            }

            img {
              width: 16px;
              height: 16px;
              object-fit: cover;
            }
          }
        }
      }
    }

    .detail-assigned-list-exercise-container {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow-y: auto;
      padding: 16px 0;
      max-height: var(--height-list);

      .detail-assigned-list-exercise-content {
        width: 90%;
        gap: 16px;
        display: flex;
        flex-direction: column;

        .detail-assigned-item-exercise:hover {
          opacity: 0.8;
        }

        .detail-assigned-item-exercise {
          width: 100%;
          background-color: #FFFFFF;
          border-radius: 8px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          padding: 12px 12px 16px;
          display: flex;
          cursor: pointer;

          .detail-assigned-exercise-section {
            width: 50%;
          }

          .detail-assigned-exercise-section.left-side {
            border-right: 1px solid #E5E5E5;
            display: flex;
            gap: 16px;
            padding-right: 24px;
          }

          .detail-assigned-exercise-section.right-side {
            padding-left: 24px;
          }

          .detail-assigned-avatar-exercise {
            width: 65px;
            height: 65px;
            border-radius: 8px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }

          .detail-assigned-info-exercise {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 3px;

            .detail-assigned-exercise-name {
              text-wrap: wrap;
              color: #00A69C;
              font-size: 18px;
              font-weight: bold;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 2;
              line-height: 24px;
              -webkit-box-orient: vertical;

              span {
                color: #FFFFFF;
                font-size: 10px;
                font-weight: bold;
                padding: 6px 16px;
                border-radius: 20px;
                margin-right: 8px;
                vertical-align: middle;
              }
            }

            p {
              font-size: 18px;
              line-height: 23.6px;
              // height: 24px;
            }
          }

          .detail-assigned-score-exercise {
            display: flex;
            flex-direction: column;
            gap: 3px;

            p,
            span {
              font-size: 18px;
              line-height: 23.6px;
              height: 24px;
            }

            .detail-assigned-score-statistic {
              display: flex;
              flex-wrap: wrap;

              .detail-assigned-score-statistic-item {
                display: flex;
                gap: 6px;
                align-items: center;
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}
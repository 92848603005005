.skills_assign_container {
  .content_selection {
    width: 100%;
    padding-top: 6.5vh;
    padding-right: 0;
    position: relative;

    .wh20.chk-custom-gr label:before {
      padding: 10px;
    }
    .chk-custom-gr {
      label {
        &::before {
          margin-right: 0 !important;
        }
      }
    }
  }
}

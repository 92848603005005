.skills_assign_container {
  .content_selection {
    padding-top: 0;
    margin-top: 4vh;
    padding-right: 4.8%;
    margin-right: -14px;

    .proposal_item {
      background-color: var(--white-color);

      .proposal_header {
        border: 1px solid #cccccc;
        background-color: var(--white-color);
        width: 100%;
        padding: 0.8rem 0.9rem;
        padding-right: 1.2rem;

        .img_avt_container {
          border-radius: 50%;
          background-color: var(--white-color);
          width: 4.2rem;
          height: 4.2rem;

          img {
            border-radius: 50%;
            // padding: 0.28rem;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border: 0.25rem solid var(--white-color);
          }
        }
      }

      .lesson_item {
        padding: 1.1rem 0.9rem;
        padding-right: 0;
        background-color: #f3ffff;
        border-bottom: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;

        .lesson_content {
          width: 100%;
          max-width: 100%;

          .lesson_content_text {
            width: 85%;
            max-width: 85%;
          }
        }

        .img_lesson {
          img {
            width: 3.4rem;
            height: 3.4rem;
            min-width: 3.4rem;
            border-radius: 0.5rem;
          }
        }

        .title_level {
          min-width: 3.9rem;
          height: 1.25rem;
          border-radius: 1rem;
          top: 0;
          margin-right: 0.55rem;
        }

        .title_lesson {
          color: #00a69c;
          top: 0.12rem;
        }

        .lesson_name {
          color: #00a69c;
        }

        .ico_delete_lesson {
          bottom: 1rem;
          right: 1rem;
          z-index: 9;

          img {
            width: 1.35rem;
            z-index: 9;
          }
        }
      }

      .add_lesson_container {
        margin: 0;
        padding: 1.1rem 0;
        background-image: linear-gradient(to right, #00b9b7, #00e1a0);
        cursor: pointer;

        &:hover {
          background: #00c0b4;
          color: #ffffff;
        }

        .btn_add_lesson {
          background-color: transparent;
          border: none;

          .img_add_container {
            width: 1.25rem;
          }
        }
      }
    }
  }

  .edit_container {
    margin-right: 0.75rem;

    .img_edit_container {
      position: relative;
      top: -0.15rem;
      margin-right: 0.4rem;

      img {
        width: 1.45rem;
      }
    }
  }

  .modal_skills_container {
    width: 72%;
    min-width: 50rem;
    max-width: 100rem;
    // min-height: 40rem;
    height: 98%;
    max-height: 46rem;
    background-color: var(--white-color);
    border-radius: 1rem;
    padding: 0 0.75% 1.5% 4.6%;
    justify-content: space-between;
    flex-direction: column;

    .btn_action_container {
      margin: 0;
      text-align: center;
      padding-top: 2%;

      .btn_action {
        text-align: center;
        width: 9rem;
        min-width: 9rem;
        margin: 0 4%;
      }
    }
  }

  .modal_skills_container_lost_root {
    width: unset;
    min-width: unset;
    max-width: unset;
    // min-height: 40rem;
    height: unset;
    max-height: unset;
    padding: 30px 20px 50px;
  }
}
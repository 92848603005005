.selection_assign {
  overflow: hidden;
  .content_selection {
    width: 100%;
    // height: 82%;
    max-height: 60rem;
    overflow: auto;
    padding-top: 1.5rem;
    flex: 1;
    .list_assign_selection {
      width: 100%;
      justify-content: space-evenly;
      .item_assign_selection {
        width: 42%;
        max-width: 42%;
        margin-bottom: 1.2rem;
        background-color: var(--white-color);
        border: 1px solid #e5e5e5;
        padding: 1.2rem 1.3rem;
        border-radius: 1rem;
        min-height: 13.3rem;
        .item_selection_header {
          margin-bottom: 1.25rem;
          .item_selection_header_left {
            .icon_assign {
              width: 1.6rem;
            }
          }
          .item_selection_header_right {
            .chk-custom-gr {
              label {
                &::before {
                  margin-right: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn_continue_selection {
    // right: 1.25rem;
    // bottom: 1.5rem;
    margin: 0.8rem 0 5px !important;
    .btn-default {
      width: 120px !important;
      padding: 0 !important;
    }
  }
}

.bar_range {
  padding: 1.15rem 0rem 0rem;

  .bar__progress {
    // flex: 1;
    // margin: 0 20px;
    height: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 1.5rem;
    position: relative;

    .bar__progress__knob {
      position: absolute;
      height: 1.6875rem;
      width: 1.6875rem;
      border: 1.5px solid #ffdb5a;
      border-radius: 50%;
      background-color: var(--white-color);
    }
  }

  .skill_item {
    background-color: #e8e8e8;
    border-radius: 1.2rem;
    padding: 0.13rem 0.7rem;
    margin-right: 1.3%;
  }
}

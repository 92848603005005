.sub-menu-button {
  position: relative;
  border-radius: 50%;
  padding: 2px;
  width: 30px;
  height: 30px;
}

.sub-menu-button:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.menu-container {
  position: absolute;
  right: calc(100% + 16px);
  top: 0;
  background-color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: default;
}

.menu-container::after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  right: -25px;
  border-width: 12px 13px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  -webkit-filter: drop-shadow(0px 0px 0px rgba(122, 122, 122, 0.3));
  filter: drop-shadow(0px 0px 0px rgba(122, 122, 122, 0.3));
}
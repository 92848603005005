/* Curriculum */
#sunE_map_curriculum {
  transition: transform 0.3s ease;
}

.sunE_map_curriculum {
  min-height: 66vh !important;
  user-select: none;

  .footer_curriculum {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.8rem 0 1rem;

    //   bottom: 0;
    .btn-primary {
      border: none;
      padding: 0px 2rem;
      margin: 0px 1rem;
      border-radius: 1.5rem;
      color: #fff;
      // margin-left: 6px;
      height: 39px;
      font-family: "Myriadpro-SemiBold";
      font-size: 18px;
      margin-top: 3px;
      background-image: linear-gradient(to right, #00b9b7, #00e1a0);
      display: flex;
      align-items: center;

      &:disabled {
        background: #70707070;
      }
    }
  }
}

// Home Page Study Guide Student
.ico_zoom_out {
  bottom: 0.7rem;
  right: 0.4rem;
  width: 2.2rem;
  z-index: 2;
}

// Home Page Student
.student-homepage {
  min-height: 32rem;

  .content_homepage_student {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .homepage-data {
      flex: 2.2;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 74%;
      padding-top: 1.8vh;
      padding-left: 0.85rem;

      .title-homepage-content {
        padding: 0.5rem 0 1rem;

        @media screen and (max-height: 800px) {
          padding: 0.35rem 0 0.75rem;
        }
      }

      .hompage-list-exercise {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        padding-right: 0.85rem;

        .homepage_exercise_item {
          width: 100%;
          background-color: var(--white-color);
          border: 1.4px solid #e5e5e5;
          padding: 0.25rem;
          padding-right: 0;
          border-radius: 0.65rem;
          margin-bottom: 0.75rem;

          @media screen and (max-height: 800px) {
            margin-bottom: 0.5rem;
          }

          .homepage_exercise_img {
            min-width: 5rem;
            min-height: 5rem;
            width: 5rem;
            height: 5rem;
            margin-right: 1.2rem;

            img {
              width: 100%;
              height: 100%;
              border-radius: 0.5rem;
            }
          }
        }
      }

      .homepage-btn-more {
        color: var(--text-color);
        text-align: center;
        padding-right: 0.75rem;
      }
    }

    .slider_homepage_student {
      background-color: #ccf1ea;
      border-radius: 1rem;

      .slider-custom-student {
        margin: 1rem 0;

        @media screen and (max-height: 800px) {
          margin: 0.5rem 0;
        }

        .slider_student_wrapper {
          display: inline-flex !important;
          justify-content: center;

          .slider-item-content {
            width: 74%;
            margin-top: 1rem;
            margin-bottom: 1rem;

            @media screen and (max-height: 800px) {
              margin-top: 0.8rem;
            }

            .img-thumb-student {
              height: 150px;
              width: 150px;
              margin: 10px 10px 10px 0;

              @media screen and (max-height: 800px) {
                margin-top: 0rem;
                margin-bottom: 0rem;
              }
            }
          }

          .slider-item-desc {
            padding: 0.75rem;

            @media screen and (max-height: 800px) {
              padding: 0.5rem 0.65rem;
            }

            .divider_content_slide {
              width: 40%;
              height: 1px;
              background-color: var(--text-color);
              margin: 0.8rem 0;
            }
          }
        }
      }
    }

    .text_exercise--empty {
      padding: 1rem 0;
    }
  }
}

.refresh-curriculum-btn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.refresh-curriculum-btn:hover {
  opacity: 0.8;
}

@media screen and (max-height: 800px) {
  .refresh-curriculum-btn {
    width: 37px;
    height: 37px;
    margin-left: 16px;
  }
}

@media screen and (max-height: 700px) {
  .refresh-curriculum-btn {
    width: 32px;
    height: 32px;
  }
}
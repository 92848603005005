.assign_specific_container {
  //   height: 100%;
  overflow: auto;
  overflow-x: hidden;
  .content_selection {
    width: 100%;
    margin: 0 auto;
    padding-top: 3vh;
    padding-left: 1rem;
    overflow: auto;
    padding-right: 6%;
    margin-right: -0.7rem;
    flex: 1;

    .content_unit {
      padding: 0.9rem 0;

      .text_unit {
        min-width: 8rem;
        text-align: center;
      }
    }

    .btn_unit_container {
      .btn {
        border-radius: 50%;
        background-color: #00e2a0;
        border: none;
        outline: none;
        width: 2rem;
        height: 2rem;

        img {
          height: 70%;
        }
      }
    }

    .content_rate {
      padding: 1.2rem 0 0;

      .content_rate_desc {
        font-style: italic;
        margin: 0.6rem 0rem 0;
      }

      .list_range_study {
        width: 68%;
        max-width: 68%;
        padding-right: 0.8rem;
      }

      .list_standard_skill {
        width: 52%;
        max-width: 52%;
        height: 100%;

        .standard_skill_container {
          width: 84%;
          max-width: 84%;
          background-color: var(--white-color);
          border-radius: 0.8rem;
          padding: 0.9rem 1.5rem 0.6rem;
          min-height: 75%;
          margin-bottom: 0.3rem;

          .standard_skill_item {
            padding: 0.2rem 0 0.4rem;

            div {
              line-height: normal;
            }
          }

          .title_standard {
            background-color: var(--light-red-color);
            margin-right: 3%;
            border-radius: 0.5rem;
            padding: 0.4rem 0;
          }

          .title_standard_right {
            margin-right: 0;
            margin-left: 3%;
          }
        }
      }
    }
  }

  .content_selection_capacity {
    padding-top: 1.7vh;
  }
}
